import React, { useRef, useEffect } from "react"

import Meta from '../components/meta';

import Contents from '../components/contents';

import Movie from '../mov/eyecatch.mp4';



const Home = () =>{

  const valueRef = useRef(null);

  useEffect(() => {

    const video = valueRef.current;

      sessionStorage.setItem('movie','true')

      video.onloadeddata = function(){
        document.querySelector('.p-home-movie--mov').parentNode.classList.add('is-active');

        video.play();
      }

      let chkMov = sessionStorage.getItem('movie')

      if(chkMov === 'true'){
        document.querySelector('.p-home-movie--mov').parentNode.classList.add('is-active');

        video.play()
      }

  }, []);



  return(
    <>

      <Meta
          title=""
          description="アクロスソリューションズは、皆様の会社のしくみを創る企業です。"
      />

      <Contents position="home">
          <div className="p-home">
            <div className="p-home-copy">
              <em className="p-home-copy--eng u-josef">
              MAKE A STRUCTURE
              </em>

              <h1 className="p-home-copy--title">
              しくみを創る企業。
              </h1>

              <p className="p-home-copy--text">
              言われたものだけを創っていては、お客様のお役に立てません。<br />
              お客様は何がしたいのか。お客様の目的は何なのか。お客様のゴールはどこなのか。<br />
              しくみまで考え、ソリューションとしてご提供します。
              </p>
            </div>

            <div className="p-home-bg"></div>
            <div className="p-home-effect"></div>

            <div className="p-home-movie">
              <video preload="true" playsInline autoPlay="" muted="muted" loop className="p-home-movie--mov" poster="" id="js-mov" ref={valueRef}>
                <source type="video/mp4" src={Movie} />
                <track kind="captions" src="" srcLang="ja" />
              </video>
            </div>
          </div>
      </Contents>

    </>
  )
  
}


export default Home;


